
import React, { PropTypes } from 'react';
import I18n from 'i18n-js';
import { Button, Popover, OverlayTrigger } from 'react-bootstrap';

import CompetitorsListContainer from '../containers/competitors_list_container';

export default class OpcCategory extends React.Component {
  constructor(props) {
    super(props);

    this.state = { open: false || props.isOpen }
  }

  componentDidMount() {
    const { category } = this.props;
    const _this = this;

    $(document).on("show.bs.collapse", `#collapse-${category.id}`, (ev) => {
      _this.props.onOpen && _this.props.onOpen(category);
      _this.setState({ open: true });
    })

    $(document).on("hide.bs.collapse", `#collapse-${category.id}`, (ev) => {
      _this.setState({ open: false });
    })
  }

  render() {
    const { category, color, admin, errors } = this.props;

    const popoverErrors = (
      <Popover id="popover-trigger-click-root-close" title="Errors">
        <ul style={{ listStyleType: 'none', padding: 0 }}>
          {errors.map((error, index) => (
            <li key={index}>
              {error === 'academy_with_three_or_more_athletes' && (
                <strong>{I18n.t('activerecord.errors.messages.categories.academy_with_three_or_more_athletes.description')}</strong>
              )}
            </li>
          ))}
        </ul>
      </Popover>
    )

    const competitors = this.state.open ?
      <CompetitorsListContainer
        category={category}
        showSubscribeButton={admin && !category.subscriptions_closed}
        printReceipt={this.props.printReceipt}
        onSubscribe={this.props.onSubscribe}
        onUnsubscribe={this.props.onUnsubscribe}
        onShowUnsubscriptionHistoryDialog={this.props.onShowUnsubscriptionHistoryDialog} /> :
      null;

    const toggleSwitch = this.props.toggleSwitch ?
      <div className='opc-category-actions'>
        { errors.length > 0 && (
          <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={popoverErrors}>
            <Button bsStyle='danger' bsSize='small' style={{marginRight: '5px'}}>
              { I18n.t('activerecord.errors.messages.categories.errors_label') }
              <i className='fa fa-question-circle' style={{marginLeft: '5px'}} ></i>
            </Button>
          </OverlayTrigger>
        )}
        <this.props.toggleSwitch/>
      </div> :
      null;

    return (
      <div className="panel panel-default">
        <div className={`panel-heading panel-heading--${color} opc-category--${!category.subscriptions_closed ? 'open' : 'closed'}`} role="tab" id={`${category.id}`}>
          <h4 className="panel-title">
            <a role="button" data-toggle="collapse" data-parent="#opc-list" href={`#collapse-${category.id}`}>
              {category.name}
            </a>
          </h4>
          {toggleSwitch}
        </div>
        <div id={`collapse-${category.id}` } className="panel-collapse collapse" role="tabpanel">
          <div className="panel-body">
            <table className="table table-striped table-hover">
              <thead>
                <tr>
                  <th/>
                  <th>Name</th>
                  <th>Division</th>
                  <th>Placement</th>
                  <th>Academy</th>
                  <th></th>
                </tr>
              </thead>
              {competitors}
            </table>
          </div>
        </div>
      </div>
    )
  }
}

OpcCategory.propTypes = {
  category: PropTypes.object.isRequired,
  color: PropTypes.string,
  toggleSwitch: PropTypes.func,
  onOpen: PropTypes.func,
  errors: PropTypes.array
}
