import React, { PropTypes } from 'react';
import R from 'ramda';
import CategoryFilter, { filterCategories } from '../shared/components/category_filter';
import mountFetch from '../modules/mount_fetch';
import Routes from '../modules/routes.js.erb';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import I18n from 'i18n-js';

class CategoriesList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hideEmpty: props.hideEmptyCategories,
      selected: [],
      filter: {
        age_division_ids: [],
        weight_division_ids: [],
        gender_ids: [],
        belt_ids: [],
      },
    }
  }

  filterCategories(categories) {
    const {
      hideEmpty,
      filter
    } = this.state;

    return R.pipe(
      (categories) =>
        hideEmpty ?
          R.reject((c) => c.competitors_count == 0, categories) :
          categories,
      filterCategories(filter)
    )(categories);
  }

  renderCategoryLink(url, category) {
    const tooltipErrors = (
      <Tooltip id="tooltip">
        {I18n.t('activerecord.errors.messages.categories.academy_with_three_or_more_athletes.bracket_not_generated')}
      </Tooltip>
    )
    
    const { competitors_count, academy_with_three_or_more_athletes, name } = category

    if (competitors_count > 0 && !academy_with_three_or_more_athletes) {
      return <a href={url} target="_blank">{name}</a>;
    }

    if (competitors_count > 0 && academy_with_three_or_more_athletes) {
      return (
        <span>
          {name}
          <OverlayTrigger trigger="hover" placement="right" overlay={tooltipErrors}>
            <i className='fa fa-question-circle' style={{ marginLeft: '5px', color: '#d9534f' }}></i>
          </OverlayTrigger>
        </span>
      );
    }

    return <span>{name}</span>;
  }

  isSelected(categoryId) {
    const {
      selected
    } = this.state;

    return R.contains(categoryId, selected);
  }

  anySelected() {
    const {
      selected
    } = this.state;

    return selected.length > 0;
  }

  selectAll(allSelected) {
    const {
      categories,
      selected
    } = this.props;

    this.setState({ selected: allSelected ? R.map(R.prop('id'), this.filterCategories(categories)) : [] })
  }

  renderRowClasses(category) {
    return R.cond([
      [(c) => this.isSelected(category.id), (_) => 'info'],
      [R.propEq('academy_with_three_or_more_athletes', true), (_) => 'danger'],
      [R.propEq('competitors_count', 0), (_) => 'warning'],
      [R.propEq('valid', false), (_) => 'danger'],
      [R.T, (_) => ''],
    ])(category)
  }

  renderFilterClasses() {
    return this.anySelected() ? '' : 'disabled';
  }

  renderBoolColumn(value) {
    return (
      <i className={`fa fa-${value ? 'check' : 'times'}`}/>
    )
  }

  renderDrawingMethod(value) {
    return (
      <span className={`text-${value === 'ranking' ? 'success' : 'danger'}`}>
        {value}
      </span>
    );
  }

  onChangeBoolState(e) {
    this.setState({ [e.target.name]: e.target.checked })
  }

  onChangeArrayState(e) {
    this.setState({
      [e.target.name]: R.ifElse(
        R.contains(parseInt(e.target.value, 10)),
        (selected) => R.difference(selected, [parseInt(e.target.value, 10)]),
        R.append(parseInt(e.target.value, 10))
      )(this.state.selected)
    })
  }

  onChangeCategoryFilter(value) {
    this.setState({
      filter: value
    })
  }

  onClickAction(data) {
    const {
      selected
    } = this.state;

    const {
      tournamentId
    } = this.props;

    if (!data.confirm || confirm(data.confirm)) {
      mountFetch(data.url, {
        method: data.method || 'POST',
        query: data.query || {},
        body: JSON.stringify(R.mergeWithKey((k, l, r) => R.merge(l, r),
          { tournament: { id: tournamentId, category_ids: selected } },
          data.body || {}))
      })
      .then((response) => data.redirect ? location.href = data.redirect : location.reload())
    }
  }

  renderTable(categories) {
    return (
      <div >
        <table className="table table-striped table-hover">
          <thead>
            <tr>
              <th>
                <input type="checkbox" checked={this.anySelected()} onChange={(e) => this.selectAll.bind(this)(e.target.checked)}/>
              </th>
              <th>Name</th>
              <th>Competitors</th>
              <th>Drawn</th>
              <th>Drawing</th>
              <th>Public Ranking</th>
              <th>Subscription on Previous Day</th>
              <th>Published</th>
              <th>Valid</th>
              <th>Last Update</th>
            </tr>
          </thead>
          <tbody>
            {
              R.addIndex(R.map)((c, i) =>
                <tr className={this.renderRowClasses(c)} key={i}>
                  <td>
                    <div className="check_box">
                      <label>
                        <input name="selected" type="checkbox" value={c.id} onChange={this.onChangeArrayState.bind(this)} checked={this.isSelected(c.id)}/>
                      </label>
                    </div>
                  </td>
                  <td>{this.renderCategoryLink(Routes.admin_tournament_category_path(this.props.tournamentId, c.id), c)}</td>
                  <td>{c.competitors_count}</td>
                  <td>{this.renderBoolColumn(c.drawn)}</td>
                  <td>{this.renderDrawingMethod(c.drawing_method)}</td>
                  <td>{this.renderBoolColumn(c.is_ranking_public)}</td>
                  <td>{this.renderBoolColumn(c.subscriptions_on_previous_day)}</td>
                  <td>{this.renderBoolColumn(c.published)}</td>
                  <td>{this.renderBoolColumn(c.valid)}</td>
                  <td>{c.last_update}</td>
                </tr>, this.filterCategories(this.props.categories))
            }
          </tbody>
        </table>
      </div>
    );
  }

  renderButtonGroup(title, icon, options) {
    const tooltip = <Tooltip id={`tooltip-${icon}`}>
      {title}
    </Tooltip>

    return (
      <OverlayTrigger placement={"top"} overlay={tooltip} rootClose={true}>
        <div className="btn-group" title={title} data-toggle='tooltip' data-placement='top'>
          <button className={`btn btn-default dropdown-toggle ${this.renderFilterClasses()}`} data-toggle="dropdown">
            <i className={`fa fa-${icon}`}/>
            <span className="caret"></span>
          </button>
          <ul className="dropdown-menu">
            {
              R.addIndex(R.map)((o, i) => (<li key={i}><a href="#" onClick={() =>this.onClickAction.bind(this)(o.data)}>{o.name}</a></li>), options)
            }
          </ul>
        </div>
      </OverlayTrigger>
    );
  }

  renderTableActions(filters) {
    const {
      hideEmpty
    } = this.state;

    const {
      tournamentId,
      actions
    } = this.props;

    const actionButtons = R.addIndex(R.map)((a, i) =>
      <li key={i}>{this.renderButtonGroup(a.name, a.icon, a.options)}</li>,
      actions
    )

    return (
      <span className="category-list__action-bar">
        <span className="category-list__actions">
          <ul className="list-unstyled list-inline">
            {actionButtons}
          </ul>
          <span className="checkbox">
            <label>
              <input name="hideEmpty" type="checkbox" checked={hideEmpty} onChange={this.onChangeBoolState.bind(this)}/>
              Hide empty categories
            </label>
          </span>
        </span>
        <CategoryFilter filters={filters} onChange={this.onChangeCategoryFilter.bind(this)}/>
      </span>
    );
  }

  render() {
    const {
      filters,
      categories
    } = this.props;

    const {
      selected
    } = this.state;

    return (
      <div className="category-list">
        {this.renderTableActions(filters)}
        {this.renderTable(categories)}
      </div>
    );
  }
}

CategoriesList.propTypes = {
  tournamentId: PropTypes.number.isRequired,
  categories: PropTypes.array,
  filters: PropTypes.array,
  hideEmptyCategories: PropTypes.bool,
  actions: PropTypes.array,
}

CategoriesList.defaultProps = {
  hideEmptyCategories: true,
  categories: [],
  filters: [],
  actions: [],
}

export default CategoriesList;
