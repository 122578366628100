
import R from 'ramda';
import React from 'react';
import { connect } from "react-redux";
import { Button, ButtonGroup, ButtonToolbar } from 'react-bootstrap';

const mapStateToProps = (state, ownProps) => {
  const { competitors, tournamentSubscriptions } = state.app;

  return {
    loading: tournamentSubscriptions.isFetching,
    competitors: competitors.result,
    tournamentSubscriptions: tournamentSubscriptions.result
  };
}

class CompetitorsListContainer extends React.Component {
  competitorList(competitors, subscribedCompetitors) {
    return R.pipe(
      R.sortWith([
        R.ascend(R.prop('club')),
        R.descend(R.prop('weight_division_order')),
        R.ascend(R.prop('placement'))
      ]),
      R.reduce((acc, c) => {
        if (R.contains(String(c.id), R.keys(subscribedCompetitors))) {
          acc.clubsCount[c.club] = R.isNil(acc.clubsCount[c.club]) ? 1 : acc.clubsCount[c.club] + 1;
          acc.subscribed = R.append(c, acc.subscribed)
        } else {
          acc.unsubscribed = R.append(c, acc.unsubscribed)
        }

        return acc;
      }, { subscribed: [], unsubscribed: [], clubsCount: {} })
    )(competitors)
  }

  render() {
    const {
      competitors,
      loading,
      category,
      tournamentSubscriptions,
      showSubscribeButton,
    } = this.props;

    const subscribedCompetitors = R.reduce(
      (acc, s) => R.assoc(s.competitor_id, s.id, acc),
      [],
      tournamentSubscriptions
    );

    const _competitorList = this.competitorList(competitors, subscribedCompetitors);

    const row = (c, styles, actions) =>
      <tr className={styles} key={`competitor-${c.id}`}>
        <td>
          <img
            className = "opc-container__picture"
            src = {c.photo}
            onError = {({target}) => {
              if (!target.src.includes("/images/competitor-placeholder.png")) {
                target.src = "/images/competitor-placeholder.png";
              }
            }}
          />
        </td>
        <td>{c.name}</td>
        <td>{c.weight_division_name}</td>
        <td>{c.placement}</td>
        <td>{c.club}</td>
        <td>{actions}</td>
      </tr>

    return (
      <tbody>
        <tr id={`unsubscribed-${category.id}`} className="opc-container__separator-row">
          <td/><td/><td/><td/><td/>
          <td>
            <a href={`#subscribed-${category.id}`}>
              <i className="fa fa-arrow-down"></i>
              <span>To Subscribed</span>
            </a>
          </td>
        </tr>
        {
          _competitorList.unsubscribed.map((c, i) => {
            return row(
              c,
              '',
              <ButtonToolbar>
                { (showSubscribeButton || !category.subscriptions_closed) && (
                  <ButtonGroup>
                    <Button
                      className="btn btn-default"
                      disabled={loading}
                      onClick={() => this.props.onSubscribe(c, category)}>

                      Subscribe
                    </Button>
                  </ButtonGroup>
                ) }

                <ButtonGroup>
                  <Button
                    className='btn btn-default'
                    disabled={loading}
                    title="View the unsubscription log"
                    onClick={() => this.props.onShowUnsubscriptionHistoryDialog(c, category)}>
                    <i className="fa fa-history"></i>
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
            );
          })
        }
        <tr id={`subscribed-${category.id}`} className="opc-container__separator-row">
          <td><span>Inscritos: {_competitorList.subscribed.length}</span></td>
          <td/><td/><td/><td/>
          <td>
            <a href={`#unsubscribed-${category.id}`}>
              <i className="fa fa-arrow-up"></i>
              <span>To Unsubscribed</span>
            </a>
          </td>
        </tr>
        {
          _competitorList.subscribed.map((c, i) => {
            return row(
              c,
              _competitorList.clubsCount[c.club] > 2 ? 'danger' : 'info',
              <ButtonToolbar>
                { (showSubscribeButton || !category.subscriptions_closed) && (
                  <ButtonGroup>
                    <Button
                      className='btn btn-default btn-danger'
                      disabled={loading}
                      onClick={() => this.props.onUnsubscribe(subscribedCompetitors[c.id])}>

                      Unsubscribe
                    </Button>
                  </ButtonGroup>
                ) }

                <ButtonGroup>
                  <Button
                    className='btn btn-default'
                    disabled={loading}
                    title="Print the receipt again"
                    onClick={() => this.props.printReceipt(c, category)}>
                    <i className="fa fa-print"></i>
                  </Button>
                </ButtonGroup>
              </ButtonToolbar>
            )
          })

        }
      </tbody>
    )
  }
}

export default connect(mapStateToProps)(CompetitorsListContainer);
