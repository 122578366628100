import { PropTypes } from 'react';
import ScoreControl from './score_control';
import R from 'ramda';

import {
  matchCompetitorClubName
} from '../../modules/match_lenses';

export default function ScoreCompetitor(props) {
  let {
    competitor,
    tournamentId,

    controls
  } = props;

  return (
    <div>
      <div className="col-xs-5">
        <span className="score-competitor__club-icon">
        </span>
        <div className="score-competitor__description">
          <img className="score-competitor__picture" src={competitor.photo}/>
          <div className="score-competitor__name">
            {competitor.name}
          </div>
          <div className="score-competitor__club-name">
            {competitor.club}
          </div>
        </div>
      </div>
      <div className="col-xs-7">
        <div className="score-competitor__scoreboard">
          <div className="score-competitor__points">
            {competitor.points}
          </div>
          <div className="score-competitor__advantages">
            {competitor.advantage_points}
          </div>
          <div className="score-competitor__fouls">
            {competitor.foul_points}
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            {controls}
          </div>
        </div>
      </div>
    </div>
  )
}

ScoreCompetitor.propTypes = {
  competitor: PropTypes.object.isRequired,

  controls: PropTypes.element
}

ScoreCompetitor.defaultProps = {
  controls: null,
}
