
import R from 'ramda';
import React, { Component, PropTypes } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import OpcReceipt from '../../modules/opc_receipt';

import {
  loadData,
  reloadCategory,
  changeCategoryFilters,
  fetchValidOpenClassCompetitorsForCategory,
  fetchTournamentSubscriptionsForCategory,
  fetchUnsubscriptionHistory,
} from '../actions';

import {
  updateCategory,
  createTournamentSubscription,
  destroyTournamentSubscription,
} from '../../shared/actions/api';

import CableSubscriber from '../../shared/components/cable_subscriber';
import OpcCategory from '../components/opc_category';
import CategoryFilter from '../../shared/components/category_filter';
import OperatorSubscriptionSwitch from '../components/operator_subscription_switch';
import AdminSubscriptionSwitch from '../components/admin_subscription_switch';
import OpcUnsubscribeDialog from '../components/opc_unsubscribe_dialog';
import OpcUnsubscriptionHistoryDialog from '../components/opc_unsubscription_history_dialog';
import ReceiptPrintingTester from '../../shared/components/receipt_printing_tester';
import I18n from 'i18n-js';

const mapStateToProps = (state, ownProps) => {
  return {
    tournamentId: ownProps.tournamentId,
    isAdmin: ownProps.isAdmin,
    tournament: state.app.tournament.result,
    tournamentSubscriptions: state.app.tournamentSubscriptions.result,
    updatingCategory: state.app.categories.isFetching,
    categories: state.app.categories.result,
    filters: state.app.filters.result,
    unsubscriptionHistory: state.app.unsubscriptionHistory.result
  };
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    loadData,
    reloadCategory,
    changeCategoryFilters,
    fetchValidOpenClassCompetitorsForCategory,
    updateCategory,
    createTournamentSubscription,
    destroyTournamentSubscription,
    fetchTournamentSubscriptionsForCategory,
    fetchUnsubscriptionHistory,
  }, dispatch);
}

class OpcContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      unsubscribeDialog: {show: false, subscriptionId: null},
      unsubscriptionHistoryDialog: {show: false, data: null}
    };
  }

  componentDidMount() {
    this.props.loadData(this.props.tournamentId)
  }

  async toggleCategorySubscriptions(category, isOpen) {
    await this.props.fetchTournamentSubscriptionsForCategory?.(category);

    if (isOpen) {
      const errors = this.verifyErrors(category)

      if (errors.length > 0) return

      this.props.updateCategory({ id: category.id, subscriptions_closed: isOpen })
    } else if (this.confirmReopening()) {
      this.props.updateCategory({ id: category.id, subscriptions_closed: isOpen });
    }
  }

  confirmReopening() {
    return confirm(I18n.t('activerecord.errors.messages.categories.confirm_reopening'));
  }

  printReceipt(competitor, category) {
    const subscription = this.props.tournamentSubscriptions.find(s => {
      return s.competitor_id === competitor.id && s.category_id === category.id
    })

    return fetch(
      `http://localhost:${this.props.opcPrintingServerPort}/`,
      {
        method: "POST",
        body: JSON.stringify(new OpcReceipt().printInstructions({
          subscription_id: subscription.id,
          tournament: this.props.tournament.name,
          competitor: competitor.name,
          club: competitor.club,
          category: category.name,
          created_at: subscription.created_at
        }))
      }
    )
  }

  createCompetitorSubscription(competitor, category) {
    if (!category.subscriptions_closed || confirm("This category's subscriptions are closed. Subscribing another competitor will erase the current bracket and generate another one. Are you sure?")) {

      this.props.createTournamentSubscription({ competitor_id: competitor.id, category_id: category.id })
        .then((r) => {
          if (!r.error) {
            return this.printReceipt(competitor, category)
          }
        })
    }
  }

  openUnsubscribeDialog(subscriptionId) {
    this.setState({
      unsubscribeDialog: {
        show: true,
        subscriptionId: subscriptionId,
        data: {reason: 'coach_request'}
      }
    })
  }

  onUnsubscribeDialogClose() {
    this.setState({ unsubscribeDialog: {show: false} })
  }

  onUnsubscribeDialogSubmit(data) {
    this.props.destroyTournamentSubscription(this.state.unsubscribeDialog.subscriptionId, {query: data})
    this.onUnsubscribeDialogClose()
  }

  showUnsubscriptionHistoryDialog(competitor, category) {
    this.props.fetchUnsubscriptionHistory(competitor, category)
    this.setState({ unsubscriptionHistoryDialog: {show: true, competitorName: competitor.name} })
  }

  onUnsubscriptionHistoryDialogClose() {
    this.setState({ unsubscriptionHistoryDialog: {show: false, data: null} })
  }

  checkAcademyWithThreeOrMoreAthletes(category) {
    const { tournamentSubscriptions } = this.props;

    const filteredSubscriptions = tournamentSubscriptions.filter(
      subscription => subscription.category_id === category.id
    );
  
    if (filteredSubscriptions.length === 0) return false

    const clubCounts = filteredSubscriptions.reduce((counts, { club_id }) => {
      counts[club_id] = (counts[club_id] || 0) + 1;
      return counts;
    }, {});

    return Object.values(clubCounts).some(count => count >= 3);
  }

  verifyErrors(category) {
    const errors = [];

    if (this.checkAcademyWithThreeOrMoreAthletes(category)) {
      errors.push('academy_with_three_or_more_athletes');
    }

    return errors;
  }

  subscriptionSwitch(category) {
    const { isAdmin, updatingCategory } = this.props;

    return isAdmin ?
      (props) =>
        <AdminSubscriptionSwitch
          open={!category.subscriptions_closed}
          loading={updatingCategory}
          errors={this.verifyErrors(category)}
          onToggle={(isOpen) => this.toggleCategorySubscriptions(category, isOpen)}
          /> :
      (props) =>
        <OperatorSubscriptionSwitch
          open={!category.subscriptions_closed}
          />
  }

  render () {
    const {
      tournamentId,
      categories,
      filters,
      reloadCategory,
      fetchValidOpenClassCompetitorsForCategory,
      opcPrintingServerPort,
      isAdmin
    } = this.props;

    const filterMappings = R.pipe(
      R.reduce((acc, f) => R.assoc(f.param, f.options, acc), {}),
      (mapped) => {
        return {
          beltSlugs: R.reduce((acc, b) => R.assoc(b.id, b.slug, acc), {}, mapped.belt_ids || []),
          beltOrder: R.reduce((acc, b) => R.assoc(b.id, b.order, acc), {}, mapped.belt_ids || []),
          ageDivisionOrder: R.reduce((acc, b) => R.assoc(b.id, b.order, acc), {}, mapped.age_division_ids || []),
          genderOrder: R.reduce((acc, b) => R.assoc(b.id, b.order, acc), {}, mapped.gender_ids || []),
        }
      }
    )(filters)

    const _categories = R.pipe(
      R.sortWith([
        R.ascend((c) => filterMappings.beltOrder[c.belt_ids[0]]),
        R.ascend((c) => filterMappings.ageDivisionOrder[c.age_division_id]),
        R.ascend((c) => filterMappings.genderOrder[c.gender_id]),
      ]),
      R.addIndex(R.map)((c, i) => {
        return (
          <OpcCategory
            admin={isAdmin}
            key={`cat-${c.id}`}
            category={c}
            color={filterMappings.beltSlugs[c.belt_ids[0]]}
            errors={this.verifyErrors(c)}
            toggleSwitch={this.subscriptionSwitch(c)}
            printReceipt={this.printReceipt.bind(this)}
            onOpen={fetchValidOpenClassCompetitorsForCategory}
            onSubscribe={this.createCompetitorSubscription.bind(this)}
            onUnsubscribe={this.openUnsubscribeDialog.bind(this)}
            onShowUnsubscriptionHistoryDialog={this.showUnsubscriptionHistoryDialog.bind(this)} />
        )})
    )(categories)

    return (
      <div className="opc-container container">
        <CableSubscriber tournamentId={+tournamentId} subscriptions={[
          { name: "CategoriesChannel", received: reloadCategory, room_id: "+"},
        ]} />

        <div className="row">
          <div className="col-sm-9">
            <CategoryFilter
              filters={filters}
              onChange={this.props.changeCategoryFilters.bind(this)}/>
          </div>

          <div className="col-sm-3">
            <ReceiptPrintingTester printingServerPort={opcPrintingServerPort} />
          </div>
        </div>

        <div className="panel-group" id="opc-list" role="tablist">
          {_categories}
        </div>

        <OpcUnsubscribeDialog
          show={this.state.unsubscribeDialog.show}
          data={this.state.unsubscribeDialog.data}
          onClose={this.onUnsubscribeDialogClose.bind(this)}
          onSubmit={this.onUnsubscribeDialogSubmit.bind(this)}
        />

        <OpcUnsubscriptionHistoryDialog
          show={this.state.unsubscriptionHistoryDialog.show}
          data={this.state.unsubscriptionHistoryDialog.data}
          competitorName={this.state.unsubscriptionHistoryDialog.competitorName}
          onClose={this.onUnsubscriptionHistoryDialogClose.bind(this)}
        />
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OpcContainer);
